<template>
	<SearchResultListItem
		:ref="CAROUSEL_ITEM"
		:result="result"
		:is-mobile="isMobile"
		:list-type="result.listType"
	>
		<template #row2="slotProps">
			<div
				class="flex mr-2.5"
				:class="{ 'text-gray-500': !slotProps.hasRating }"
			>
				<img
					:src="slotProps.starIcon"
					alt="Star Icon"
					width="16"
					height="16"
					class="mr-1"
				>
				<span class="font-bold">
					{{ slotProps.formattedAverage }}
				</span>
			</div>
			<img
				:src="result.icon"
				alt="Category Icon"
				class="mr-2"
				width="17"
				height="17"
			>
			<span class="mr-2 capitalize">
				{{ result.category }}
			</span>
			<span
				v-if="slotProps.hasStrainType"
				class="mr-2 text-gray-300"
			>
				•
			</span>
			<span class="capitalize truncate">
				{{ result.strainType }}
			</span>
		</template>

		<template #row3>
			<div class="text-left">
				<div class="flex items-center pt-1">
					<img
						src="@/assets/icons/pin.svg"
						class="mr-1"
						width="18"
						height="18"
						alt="location pin icon"
					>
					<span class="mr-1">
						in
					</span>
					<span class="font-bold">
						{{ result.businessCount }} {{ result.businessCount == '1' ? 'business' : 'businesses' }}
					</span>
				</div>
			</div>
		</template>
	</SearchResultListItem>
</template>

<script async>
import SearchResultListItem from '@/components/search/SearchResultListItem.vue'
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'

export default {
	components: {
		SearchResultListItem
	},
	props: {
		result: {
			type: Object,
			required: true
		},
		listType: {
			type: String,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		},
		compact: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			CAROUSEL_ITEM
		}
	}
}
</script>
